<template>
  <div>
    <super-builder-step3 v-if="packages.length>0" :service="service" :already-packs="packages"></super-builder-step3>
    <div class="floating-step" v-if="service.conversions.length>0">
      <div class="size-22 weight-600 margin-right-8"><span class="size-15" style="font-weight: 400;margin-right:12px">결제 금액</span> {{ totalPrice|currency }}</div>
      <button class="button is-primary margin-left-12" @click="clickAccount">결제</button>
    </div>
  </div>
</template>

<script>
  import SuperBuilderStep3 from "./QuickBuilder/SuperBuilderStep3";
  export default {
    name: "AppendPack",
    components: {SuperBuilderStep3},
    mounted() {
      window.addEventListener('message', (e) => {
        if(e.data.packages) {
          this.packages = e.data.packages;
        }
      });
    },
    data() {
      return {
        service: {
          conversions: []
        },
        packages: [],
      }
    },
    methods: {
      clickAccount() {
        window.parent.postMessage({products: this.service.conversions}, '*');
      },
    },
    computed: {
      totalPrice() {
        return this.service.conversions.length > 0 ? this.service.conversions.map(item=> { return item.price.price }).reduce((acc,cur)=> { return acc + cur }) : 0;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .floating-step
    position absolute
    left 50%
    bottom 6%
    transform translateX(-50%)
    width 350px
    padding 16px
    z-index 10
    background-color white
    box-shadow 0 4px 7px #d4d4d4
    display flex
    align-items center
    justify-content flex-end
</style>
<style lang="stylus">
  html
    overflow hidden
  #ch-plugin
    display none !important
</style>
